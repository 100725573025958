// @flow
import jwtDecode from 'jwt-decode';
import {Cookies} from 'react-cookie';

/**
 * Checks if user is authenticated
 */
const isUserAuthenticated = () => {
    const user = getLoggedInUser();
    if (!user) {
        return false;
    }
    const decoded = jwtDecode(user.jwt);
    const currentTime = Date.now() / 1000;
    if (decoded.exp < currentTime) {
        console.warn('access token expired');
        return false;
    } else {
        return true;
    }
};

/**
 * Returns the logged in user
 * admin user orgAdmin || schoolAdmin teacher student
 */
const getLoggedInUser = () => {
    const cookies = new Cookies();
    const user = cookies.get('user');
    const userObj = user ? (typeof user == 'object' ? user : JSON.parse(user)) : null;
    // console.log("User Object is : ", userObj);
    if (userObj && (userObj.user.type === "schoolAdmin" || userObj.user.type === "teacher" || userObj.user.type === "student")) {
        // console.log("Redirect to classroom");
        window.location.replace("https://classroom.lauk.in/");
    }
    return userObj;
};

export {isUserAuthenticated, getLoggedInUser};
