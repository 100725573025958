// @flow
import appConfig from '../config';
import axios from 'axios';
import { getLoggedInUser } from './authUtils';
import { isUserAuthenticated } from './authUtils';
import qs from 'qs';
import * as rdd from 'react-device-detect';

window.rdd = rdd;
const delay = async (ms) => new Promise((res) => setTimeout(res, ms));

window.onerror = function (error, url, line) {
    // controller.sendLog({acc:'error', data:'ERR:'+error+' URL:'+url+' L:'+line});
    console.log('Error received : ', { acc: 'error', data: 'ERR:' + error + ' URL:' + url + ' L:' + line });
};

const makeHeader = (jwt) => {
    let ujwt = jwt;
    if (!jwt) {
        let user = getLoggedInUser();
        ujwt = user.jwt;
    }
    return {
        headers: {
            Authorization: 'Bearer ' + ujwt,
        },
    };
};

const copyInvite = (userName, roomName, cid, type, password) => {
    let lnk = '';
    let msg = `
${userName} is inviting you to join Lauk ${type}.

${type} Name: ${roomName}
${type} ID: ${cid}

${type} Link
${appConfig.webinarroomLink + cid}
`;

    if (password) {
        msg += `
Room Password: ${password}`;
    }

    return msg;
};

/**
 * Create New Report
 */
const newReport = async (report) => {
    // console.log("saveing ", meeting);
    const header = makeHeader();
    const user = getLoggedInUser();
    report.user = user.user.id;
    console.log(report);
    try {
        return await axios
            .post(appConfig.reports, report, header)
            .then((response) => {
                // console.log('New Meeting', response.data);
                return response.data;
            })
            .catch((error) => {
                // Handle error.
                console.log('An error occurred:', error);
                return error;
            });
    } catch (error) {
        console.log(error);
    }
};

/**
 * Update User ProfileDetails
 */
const updateUserData = async (userID, data) => {
    const header = makeHeader();
    // const data = {password: pass};

    return await axios
        .put(appConfig.users + '/' + userID, data, header)
        .then((response) => {
            // console.log('User Updated : ', response.data);
            return response.data;
        })
        .catch((error) => {
            console.log('An error occurred:', error);
            return error;
        });
};

/**
 * Update User Password
 */
const updateUserPassword = async (userID, data) => {
    const header = makeHeader();
    // const data = {password: pass};

    return await axios
        .put(appConfig.users + '/' + userID, data, header)
        .then((response) => {
            // console.log('User Updated : ', response);
            return response.data;
        })
        .catch((error) => {
            console.log('An error occurred:', error);
            return error;
        });
};

/*
Upload Media
*/

const newMediaUpload = async (formData) => {
    const header = makeHeader();
    try {
        return await axios
            .post(appConfig.upload, formData, header)
            .then((response) => {
                // console.log("MEETINGS API RECEIVED : ", response);
                return response.data;
            })
            .catch((error) => {
                console.log(error);
                return [];
            });
    } catch (error) {
        console.log(error);
    }
};
/*
New Registration
*/

const newRegistration = async (data) => {
    // const header = makeHeader();
    try {
        return await axios
            .post(appConfig.registration, data)
            .then((response) => {
                // console.log("MEETINGS API RECEIVED : ", response);
                return response.data;
            })
            .catch((error) => {
                console.log(error);
                return [];
            });
    } catch (error) {
        console.log(error);
    }
};
const countRegistrations = async (id) => {
    // const header = makeHeader();

    try {
        return await axios
            .get(appConfig.registration + `/count?meeting=${id}`)
            .then((response) => {
                // console.log("MEETINGS API RECEIVED : ", response);
                return response.data;
            })
            .catch((error) => {
                console.log(error);
                return [];
            });
    } catch (error) {
        console.log(error);
    }
};
const getRegistrations = async (filter = '') => {
    // const header = makeHeader();
    let url = appConfig.registration;
    if (filter) {
        url = url + `?meeting=${filter}`;
    }

    try {
        return await axios
            .get(url)
            .then((response) => {
                // console.log("MEETINGS API RECEIVED : ", response);
                return response.data;
            })
            .catch((error) => {
                console.log(error);
                return [];
            });
    } catch (error) {
        console.log(error);
    }
};
const getRegistrationUser = async (email, password, meeting) => {
    // const header = makeHeader();
    let url = appConfig.registration + `?meeting=${meeting}&email=${email}&password=${password}`;

    try {
        return await axios
            .get(url)
            .then((response) => {
                // console.log("MEETINGS API RECEIVED : ", response);
                return response.data;
            })
            .catch((error) => {
                console.log(error);
                return [];
            });
    } catch (error) {
        console.log(error);
    }
};

export {
    makeHeader,
    copyInvite,
    newReport,
    updateUserData,
    updateUserPassword,
    newMediaUpload,
    newRegistration,
    getRegistrations,
    countRegistrations,
    getRegistrationUser,
};
