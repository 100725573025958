let mode = 'remoteserver'; // 'localserver' 'remoteserver' 'noserver'
let domain = mode === 'localserver' ? 'http://localhost:1337/' : 'https://astroapi.html5.run/';
// domain = 'http://localhost:1337/';

// let domain: 'https://eduapi.html5.run/';
// let domain: 'https://apiclsrm1.html5.run/';
// let domain: 'http://localhost:1337/';

let appConfig = {
    // domain: 'html5.run',
    domain: window.location.hostname === 'dev.html5.run' ? 'html5.run' : 'lauk.in',
    // domain: "http://localhost:1337/",

    socketURL: domain,

    reports: domain + 'reports',
    loginUrl: domain + 'auth/local',
    register: domain + 'auth/local/register',
    users: domain + 'users',

    upload: domain + 'upload',
    registration: domain + 'registrations',

    getical: domain + 'email/getics',

    // urlPublic: 'https://dev.html5.run:3000/lauk/',

    // urlPrivate: window.location.origin + "/class/",
    // urlPrivate: 'https://dev.html5.run:3000/class/',

    config: {
        // startAudioOnly: true,
        startWithAudioMuted: true,
        debug: false,
        // disable1On1Mode: true,
        doNotStoreRoom: true,
        remoteVideoMenu: {
            disableKick: true,
        },
        disableRemoteMute: true,

        disableDeepLinking: true,

        ///////
        disableThirdPartyRequests: true,
        // Disable Audio Dots
        disableAudioLevels: true,
        enableNoisyMicDetection: false,

        startWithVideoMuted: true,

        // // Disable H.264
        // // disableH264: true,
        // // p2p: {
        // //     disableH264: true,
        // // },
        // // Disables all audio processing
        disableAP: false,
        // // Disables Acoustic Echo Cancellation
        disableAEC: false,
        // // Disables Automatic Gain Control
        disableAGC: false,
        // // Disables Noise Suppression
        disableNS: false,
        // // Disables Highpass Filter
        disableHPF: true,
        ///////
        enableLayerSuspension: false,

        prejoinPageEnabled: false,
        // Limit number of video feeds forwarded
        // channelLastN: 6,
        fileRecordingsEnabled: true,
        liveStreamingEnabled: true,
        hiddenDomain: 'recorder.m2.lauk.in',
        // resolution: 480,
        // constraints: {
        //     video: {
        //         height: {
        //             ideal: 480,
        //             max: 720,
        //             min: 240
        //         }
        //     }
        // },
    },

    interfaceConfig: {
        DISABLE_VIDEO_BACKGROUND: true,
        DEFAULT_BACKGROUND: '#e5e3e3',
        // DEFAULT_BACKGROUND: '#e5e3e3',
        DEFAULT_REMOTE_DISPLAY_NAME: 'Guest',
        TOOLBAR_BUTTONS: [
            // 'microphone',
            // 'camera',
            'desktop',
            'fullscreen',
            'fodeviceselection',
            'hangup',
            // 'profile',
            'chat',
            'settings',
            'raisehand',
            'videoquality',
            'filmstrip',
            'tileview',
            // 'download',
            // 'recording',
            // 'livestreaming',
            // 'stats', 'mute-everyone', 'sharedvideo',
            // 'videobackgroundblur',
            // 'etherpad',
        ],

        // DISABLE_JOIN_LEAVE_NOTIFICATIONS: true,
        DISABLE_FOCUS_INDICATOR: true,
        DISABLE_DOMINANT_SPEAKER_INDICATOR: true,

        //
        // VERTICAL_FILMSTRIP: false,
        // filmStripOnly: true,
        SET_FILMSTRIP_ENABLED: true,
    },

    webinarToolbar: [
        // 'microphone',
        // 'camera',
        // 'desktop',
        'fullscreen',
        // 'fodeviceselection',
        'hangup',
        // 'profile',
        'chat',
        // 'settings',
        'raisehand',
        // 'videoquality',
        // 'filmstrip',
        // 'tileview',

        // 'download',
        // 'recording',
        // 'livestreaming',
        // 'stats', 'mute-everyone', 'sharedvideo',
        // 'videobackgroundblur',
        // 'etherpad',
    ],
};

export default appConfig;
