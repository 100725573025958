import React from 'react';
import { Redirect } from 'react-router-dom';
import { Route } from 'react-router-dom';
import * as FeatherIcon from 'react-feather';

import { isUserAuthenticated, getLoggedInUser } from '../helpers/authUtils';
import Reports from '../pages/reports/ReportsForm';

// auth
const Login = React.lazy(() => import('../pages/auth/Login'));
const Logout = React.lazy(() => import('../pages/auth/Logout'));
const Register = React.lazy(() => import('../pages/auth/Register'));
const ForgetPassword = React.lazy(() => import('../pages/auth/ForgetPassword'));
const Confirm = React.lazy(() => import('../pages/auth/Confirm'));
// dashboard

// User Pages

const MyAccount = React.lazy(() => import('../pages/user/myaccount'));

// handle auth and authorization
const PrivateRoute = ({ component: Component, roles, ...rest }) => (
    <Route
        {...rest}
        render={(props) => {
            if (!isUserAuthenticated()) {
                // not logged in so redirect to login page with the return url
                return <Redirect to={{ pathname: '/account/login', state: { from: props.location } }} />;
            }

            const loggedInUser = getLoggedInUser();
            console.log('logedin user', loggedInUser);
            // check if route is restricted by role
            // if (roles && roles.indexOf(loggedInUser.user.type) === -1) {
            //     // role not authorised so redirect to home page
            //     return <Redirect to={{ pathname: '/' }} />;
            // }

            // authorised so return component
            return <Component {...props} />;
        }}
    />
);

// root routes
const rootRoute = {
    path: '/',
    exact: true,
    component: () => <Redirect to="/home" />,
    route: PrivateRoute,
};

// User
const homeRoutes = {
    path: '/home',
    name: 'Report',
    icon: FeatherIcon.FilePlus,
    header: 'Lauk',
    component: Reports,
    roles: ['admin', 'user'],
    route: PrivateRoute,
};

// const logsRoutes = {
//     path: '/logs',
//     name: 'My Attendence',
//     icon: FeatherIcon.FileText,
//     // header: 'Navigation',
//     component: Attendence,
//     roles: ['admin', 'user'],
//     route: PrivateRoute,
// };

const myAccountRoutes = {
    path: '/my-account',
    name: 'My Account',
    icon: FeatherIcon.User,
    // header: 'Navigation',
    component: MyAccount,
    roles: ['admin', 'user'],
    route: PrivateRoute,
};

// const meetingsRoutes = {
//     path: '/batches',
//     name: 'My Batches',
//     icon: FeatherIcon.Airplay,
//     // header: 'Navigation',
//     component: Batches,
//     roles: ['admin', 'user'],
//     route: PrivateRoute,
// };

const appRoutes = [homeRoutes, myAccountRoutes];
// calendarAppRoutes, emailAppRoutes, projectAppRoutes, taskAppRoutes];

// auth
const authRoutes = {
    path: '/account',
    name: 'Auth',
    children: [
        {
            path: '/account/login',
            name: 'Login',
            component: Login,
            route: Route,
        },
        {
            path: '/account/logout',
            name: 'Logout',
            component: Logout,
            route: Route,
        },
        {
            path: '/account/register',
            name: 'Register',
            component: Register,
            route: Route,
        },
        {
            path: '/account/confirm',
            name: 'Confirm',
            component: Confirm,
            route: Route,
        },
        {
            path: '/account/forget-password',
            name: 'Forget Password',
            component: ForgetPassword,
            route: Route,
        },
    ],
};

// flatten the list of all nested routes
const flattenRoutes = (routes) => {
    let flatRoutes = [];

    routes = routes || [];
    routes.forEach((item) => {
        flatRoutes.push(item);

        if (typeof item.children !== 'undefined') {
            flatRoutes = [...flatRoutes, ...flattenRoutes(item.children)];
        }
    });
    return flatRoutes;
};

// All routes
const allRoutes = [
    rootRoute,
    // dashboardRoutes,
    ...appRoutes,
    // pagesRoutes,
    // componentsRoutes,
    // chartRoutes,

    // tableRoutes,
    authRoutes,
];

// const authProtectedRoutes = [dashboardRoutes, ...appRoutes, pagesRoutes, componentsRoutes, chartRoutes, formsRoutes, tableRoutes];
const authProtectedRoutes = [...appRoutes];
const allFlattenRoutes = flattenRoutes(allRoutes);
export { allRoutes, authProtectedRoutes, allFlattenRoutes };
