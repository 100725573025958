import React, {useEffect, useState} from 'react';
import {Button, Card, Col, Form, FormGroup, Input, Label, Row, Alert} from 'reactstrap';
import {newReport} from '../../helpers/apiCalls';

const ReportsForm = () => {
    const [name, setName] = useState('');
    const [lastName, setLastName] = useState('');
    const [age, setAge] = useState('');
    const [sex, setSex] = useState('Male');
    const [familyMember, setfamilyMember] = useState('');
    const [address, setAddress] = useState('');
    const [village, setVillage] = useState('');
    const [nyayPanchayat, setnyayPanchayat] = useState('');
    const [block, setBlock] = useState('');
    const [district, setDistrict] = useState('');
    const [email, setEmail] = useState('');
    const [phoneNo, setPhoneNo] = useState('');
    const [whatsApp, setWhatsApp] = useState('');
    const [oxygenLevel, setOxygenLevel] = useState('');
    const [bp, setBP] = useState('');
    const [temprature, setTemprature] = useState('');
    const [cough, setCough] = useState('');
    const [throatAlergy, setThroatAlergy] = useState('');
    const [feedback, setFeedback] = useState('');
    const [aadhaar, setAadhaar] = useState('');

    const [hName, setHName] = useState('');
    const [covidPositive, setCovidPositive] = useState('');
    const [admitDischarge, setAdmitDischarge] = useState('');

    const [color, setColor] = useState('info');
    const [open, setOpen] = useState(false);
    const [text, setText] = useState('Successfully created Report');

    useEffect(() => {
        setFocus();
    }, []);
    const setFocus = () => {
        document.getElementById('name').focus();
    };
    const clearState = () => {
        setName('');
        setLastName('');
        setAge('');
        setSex('Male');
        setfamilyMember('');
        setAddress('');
        setVillage('');
        setnyayPanchayat('');
        setBlock('');
        setDistrict('');
        setEmail('');
        setPhoneNo('');
        setWhatsApp('');
        setOxygenLevel('');
        setBP('');
        setTemprature('');
        setCough('');
        setThroatAlergy('');
        setFeedback('');
        setAadhaar('');
        // setFName('');

        setHName('');
        // setCovidPositive('');
        // setAdmitDischarge('');
    };
    const handleForm = (e) => {
        e.preventDefault();
        const data = {
            name,
            lastName,
            age,
            sex,
            familyMember,
            address,
            village,
            nyayPanchayat,
            block,
            district,
            email,
            phoneNo,
            whatsApp,
            oxygenLevel,
            bp,
            temprature,
            cough,
            throatAlergy,
            feedback,
            aadhaar,

            hName,
            // covidPositive,
            // admitDischarge,
        };

        newReport(data)
            .then((res) => {
                clearState();
                setFocus();
                setOpen(true);
            })
            .catch((err) => {
                setText('There was a error creating Report. Please try Again!!');
                setColor('danger');
                setOpen(true);
            });
    };
    
    return (
        <div>
            <Row className="my-3">
                <Card className="w-100 p-3 align-items-center">
                    <Alert color={color} isOpen={open} fade className="w-75" toggle={() => setOpen(false)}>
                        {text}
                    </Alert>
                    <h2 className="m-3">Fill Your Report Form</h2>
                    <Col xs="12" md="12" lg="6">
                        <Form onSubmit={handleForm}>
                            <FormGroup>
                                <Label for="name">Patient's Name</Label>
                                <Input
                                    onChange={(e) => setName(e.target.value)}
                                    value={name}
                                    type="text"
                                    name="email"
                                    id="name"
                                    required
                                    placeholder="First Name"
                                />
                            </FormGroup>

                            <FormGroup>
                                <Label for="name">Patient's Last Name</Label>
                                <Input
                                    onChange={(e) => setLastName(e.target.value)}
                                    value={lastName}
                                    type="text"
                                    name="lastName"
                                    id="lastName"
                                    required
                                    placeholder="Last Name"
                                />
                            </FormGroup>

                            <FormGroup>
                                <Label for="name">Patient's Age</Label>
                                <Input
                                    onChange={(e) => setAge(e.target.value)}
                                    value={age}
                                    type="text"
                                    name="age"
                                    id="age"
                                    required
                                    placeholder="Age"
                                />
                            </FormGroup>

                            <FormGroup>
                                <Label for="sex">Sex</Label>
                                <Input
                                    onChange={(e) => setSex(e.target.value)}
                                    type="select"
                                    value={sex}
                                    name="select"
                                    required
                                    id="sex">
                                    <option value="Male">Male</option>
                                    <option value="Female">Female</option>
                                    <option value="TransGender">TransGender</option>
                                </Input>
                            </FormGroup>

                            <FormGroup>
                                <Label for="name">Number of Family Member</Label>
                                <Input
                                    onChange={(e) => setfamilyMember(e.target.value)}
                                    value={familyMember}
                                    type="text"
                                    name="familyMember"
                                    id="familyMember"
                                    required
                                    placeholder="1, 2, 3"
                                />
                            </FormGroup>

                            <FormGroup>
                                <Label for="address">Patient's Address</Label>
                                <Input
                                    onChange={(e) => setAddress(e.target.value)}
                                    value={address}
                                    type="text"
                                    name="address"
                                    id="address"
                                    required
                                    placeholder="Address"
                                />
                            </FormGroup>

                            <FormGroup>
                                <Label for="address">Patient's Village</Label>
                                <Input
                                    onChange={(e) => setVillage(e.target.value)}
                                    value={village}
                                    type="text"
                                    name="village"
                                    id="village"
                                    required
                                    placeholder="Village Name"
                                />
                            </FormGroup>

                            <FormGroup>
                                <Label for="address">Patient's Nyay Panchayat</Label>
                                <Input
                                    onChange={(e) => setnyayPanchayat(e.target.value)}
                                    value={nyayPanchayat}
                                    type="text"
                                    name="nyayPanchayat"
                                    id="nyayPanchayat"
                                    required
                                    placeholder="Nayay Panchayat"
                                />
                            </FormGroup>

                            <FormGroup>
                                <Label for="address">Patient's Block</Label>
                                <Input
                                    onChange={(e) => setBlock(e.target.value)}
                                    value={block}
                                    type="text"
                                    name="block"
                                    id="block"
                                    required
                                    placeholder="Block"
                                />
                            </FormGroup>

                            <FormGroup>
                                <Label for="address">Patient's District</Label>
                                <Input
                                    onChange={(e) => setDistrict(e.target.value)}
                                    value={district}
                                    type="text"
                                    name="district"
                                    id="district"
                                    required
                                    placeholder="District"
                                />
                            </FormGroup>

                            <FormGroup>
                                <Label for="email">Patient's Email</Label>
                                <Input
                                    onChange={(e) => setEmail(e.target.value)}
                                    value={email}
                                    type="email"
                                    name="email"
                                    id="email"
                                    required
                                    placeholder="email"
                                />
                            </FormGroup>

                            <FormGroup>
                                <Label for="phoneNo">Patient's Phone Number</Label>
                                <Input
                                    onChange={(e) => setPhoneNo(e.target.value)}
                                    value={phoneNo}
                                    type="text"
                                    name="phoneNo"
                                    id="phoneNo"
                                    required
                                    placeholder="Phone Number Without +91"
                                />
                            </FormGroup>

                            <FormGroup>
                                <Label for="phoneNo">Patient's WhatsApp</Label>
                                <Input
                                    onChange={(e) => setWhatsApp(e.target.value)}
                                    value={whatsApp}
                                    type="text"
                                    name="whatsApp"
                                    id="whatsApp"
                                    required
                                    placeholder="WhatsApp Number Without +91"
                                />
                            </FormGroup>

                            <FormGroup>
                                <Label for="aadhaar">Patient's Aadhaar No</Label>
                                <Input
                                    onChange={(e) => setAadhaar(e.target.value)}
                                    value={aadhaar}
                                    type="text"
                                    name="aadhaar"
                                    id="aadhaar"
                                    required
                                    placeholder="Aadhaar Number"
                                />
                            </FormGroup>

                            <FormGroup>
                                <Label for="address">Patient's Oxygen Level</Label>
                                <Input
                                    onChange={(e) => setOxygenLevel(e.target.value)}
                                    value={oxygenLevel}
                                    type="text"
                                    name="oxygenLevel"
                                    id="oxygenLevel"
                                    required
                                    placeholder="SPO2 Level"
                                />
                            </FormGroup>

                            <FormGroup>
                                <Label for="address">Patient's BP</Label>
                                <Input
                                    onChange={(e) => setBP(e.target.value)}
                                    value={bp}
                                    type="text"
                                    name="bp"
                                    id="bp"
                                    required
                                    placeholder="BP Level"
                                />
                            </FormGroup>

                            <FormGroup>
                                <Label for="address">Patient's Temprature</Label>
                                <Input
                                    onChange={(e) => setTemprature(e.target.value)}
                                    value={temprature}
                                    type="text"
                                    name="temprature"
                                    id="temprature"
                                    required
                                    placeholder="Temprature"
                                />
                            </FormGroup>
{/* 
                            <FormGroup>
                                <Label for="address">Patient's Cough</Label>
                                <Input
                                    onChange={(e) => setCough(e.target.value)}
                                    value={cough}
                                    type="text"
                                    name="cough"
                                    id="cough"
                                    required>
                                        <option value="Yes">Yes</option>
                                    <option value="No">No</option>
                                    <option value="High">High</option>
                                    </Input>
                            
                            </FormGroup> */}
                            <FormGroup>
                                <Label for="Patient's Cough">Patient's Cough</Label>
                                <Input
                                    onChange={(e) => setCough(e.target.value)}
                                    type="select"
                                    value={cough}
                                    name="select"
                                    required
                                    id="cough">
                                    <option value="" disabled>--Select--</option>    
                                    <option value="Yes">Yes</option>
                                    <option value="No">No</option>
                                    <option value="High">High</option>
                                </Input>
                            </FormGroup>

                            <FormGroup>
                                <Label for="Throat Allergy">Throat Allergy</Label>
                                <Input
                                    onChange={(e) => setThroatAlergy(e.target.value)}
                                    value={throatAlergy}
                                    type="select"
                                    name="select"
                                    id="throatAlergy"
                                    required>
                                        <option value="" disabled>--Select--</option>    
                                    <option value="Yes">Yes</option>
                                    <option value="No">No</option>
                                    </Input>
                            </FormGroup>


                            <FormGroup>
                                <Label for="feedback">Feedback</Label>
                                <Input
                                    onChange={(e) => setFeedback(e.target.value)}
                                    value={feedback}
                                    type="textarea"
                                    name="feedback"
                                    id="feedback"
                                    rows="5"
                                />
                            </FormGroup>


                            {/*<FormGroup>*/}
                            {/*    <Label for="covidPositive">Covid Positive Date</Label>*/}
                            {/*    <Input*/}
                            {/*        onChange={(e) => setCovidPositive(e.target.value)}*/}
                            {/*        value={covidPositive}*/}
                            {/*        type="date"*/}
                            {/*        name="covidPositive"*/}
                            {/*        required*/}
                            {/*        id="covidPositive"*/}
                            {/*        placeholder="Covid Positive Date"*/}
                            {/*    />*/}
                            {/*</FormGroup>*/}
                            {/*<FormGroup>*/}
                            {/*    <Label for="admitDischarge">Admit/Discharge Date</Label>*/}
                            {/*    <Input*/}
                            {/*        onChange={(e) => setAdmitDischarge(e.target.value)}*/}
                            {/*        value={admitDischarge}*/}
                            {/*        type="date"*/}
                            {/*        name="admitDischarge"*/}
                            {/*        id="admitDischarge"*/}
                            {/*        placeholder="Admit/Discharge Date"*/}
                            {/*    />*/}
                            {/*</FormGroup>*/}
                            {/*<FormGroup>*/}
                            {/*    <Label for="hName">Hospital Name</Label>*/}
                            {/*    <Input*/}
                            {/*        onChange={(e) => setHName(e.target.value)}*/}
                            {/*        value={hName}*/}
                            {/*        type="text"*/}
                            {/*        name="hName"*/}
                            {/*        id="hName"*/}
                            {/*        required*/}
                            {/*        placeholder="Hospital Name"*/}
                            {/*    />*/}
                            {/*</FormGroup>*/}

                            <Button>Submit</Button>
                        </Form>
                    </Col>
                </Card>
            </Row>
        </div>
    );
};

export default ReportsForm;
